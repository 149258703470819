import { jsPDF } from "jspdf";
import { createI18n } from "vue-i18n";
import { uuid, ellipsisString } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { startCase } from "lodash";
import QRCode from "qrcode";
import { logoBlack } from "../../constants/images";

// LANGUAGE
const i18nInstance = createI18n({
  legacy: false,
  locale: "id",
  fallbackLocale: "id",
  globalInjection: false,
  messages: {
    en,
    id,
    my
  }
});

const translate = i18nInstance.global.t;

const headerSection = async (doc: any, item: any, logoAspectRatio: number) => {
  const newLogo = logoBlack;

  doc.addImage(
    newLogo,
    "PNG",
    5,
    3,
    32,
    logoAspectRatio * 32,
    "logo",
    "FAST",
    0
  );

  // PRODUCT SECTION
  doc.setLineWidth(0.3);
  doc.setFillColor("#333333");
  doc.setDrawColor("#333333");
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(15);

  // GENESIS BARCODE
  doc.addImage(item.barcode, "PNG", 5, 19, 69, 15, uuid(), "FAST", 0);
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(18);
  doc.text(item.sttNo, 5, 19);

  // NO REF SECTION
  doc.setFont("Montserrat", "normal");
  doc.setFontSize(6);
  doc.text("Ref: ", 5, 35);
  doc.setFont("Montserrat", "bold");
  doc.text("-", 10, 35);

  // CLIENT MOTHER NAME
  const elipsisClientName =
    item.sttBookedName.length > 40
      ? `${item.sttBookedName.substring(0, 40)}...`
      : item.sttBookedName;
  doc.text(elipsisClientName, 5, 38);

  // LINE HORIZONTAL
  doc.setLineWidth(0.4);
  doc.line(0, 40, 105, 40);

  // LINE VERTICAL
  doc.setLineWidth(0.4);
  doc.line(77, 40, 77, 0);

  const endContentY = 40;

  return {
    startX: 77,
    startY: endContentY
  };
};

const originDestination = (doc: any, item: any) => {
  // ORIGIN
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(14);
  doc.text("", 78, 7);

  // DESTINATION
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(28);
  doc.text("", 78, 17);

  // LINE HORIZONTAL
  doc.setLineWidth(0.4);
  doc.line(77, 20, 105, 20);
  const endContentY = 17;

  return {
    startX: 71,
    startY: endContentY
  };
};

const ursaSla = (doc: any) => {
  // URSA CODE
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(20);
  doc.text("", 78, 29);

  // CREATED AT
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(startCase(translate("receipt.createdAt")), 78, 33);
  doc.text(":", 87.5, 33);
  doc.setFont("Montserrat", "normal");
  doc.text("", 89, 33);

  // ESTIMATION
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(startCase(translate("receipt.estimation")), 78, 37);
  doc.text(":", 87.5, 37);
  doc.setFont("Montserrat", "normal");
  doc.text("", 89, 37);
};

const DeliverySenderReceiverSection = async (doc: any) => {
  const leftMargin = 5;
  doc.setTextColor("#333333");

  // PENGIRIM
  const senderPositionY = 40 + 4;

  const sttSenderNameData = "";
  const sttSenderText =
    startCase(translate("receipt.shipper")).toUpperCase() +
    ": " +
    sttSenderNameData.toUpperCase();

  doc.setFont("Montserrat", "bold");
  doc.setFontSize(6);
  const sttSenderTextSplitted = doc.splitTextToSize(sttSenderText, 70);

  doc.text(sttSenderTextSplitted, leftMargin, senderPositionY);

  // PENERIMA
  const sttSenderTextSplittedHeight = doc.getTextDimensions(
    sttSenderTextSplitted
  ).h;
  const sttRecipientName = "";
  const recipientPositionY = senderPositionY + sttSenderTextSplittedHeight + 1;
  const sttRecipientText =
    startCase(translate("receipt.receiver")).toUpperCase() +
    ": " +
    sttRecipientName.toUpperCase();

  const sttRecipientTextSplitted = doc.splitTextToSize(sttRecipientText, 70);

  doc.setFont("Montserrat", "bold");
  doc.setFontSize(6);
  doc.text(sttRecipientTextSplitted, leftMargin, recipientPositionY);

  return { senderPositionY, recipientPositionY, sttRecipientTextSplitted };
};

const DeliveryAddress = async (doc: any) => {
  const leftMargin = 5;
  doc.setTextColor("#333333");

  const {
    senderPositionY,
    recipientPositionY,
    sttRecipientTextSplitted
  } = await DeliverySenderReceiverSection(doc);

  // PENERIMA PHONE
  let phoneRecipientPositionY =
    recipientPositionY +
    0.5 +
    doc.getTextDimensions(sttRecipientTextSplitted).h;

  const phoneAndRecipientAddress = "";
  doc.setFont("Montserrat", "bold");
  doc.setFontSize(6);
  doc.text(phoneAndRecipientAddress, leftMargin, phoneRecipientPositionY);

  if (phoneAndRecipientAddress.length > 3) {
    phoneRecipientPositionY += 1.5;
  }

  return {
    phoneRecipientPositionY,
    senderPositionY
  };
};

const weightsSection = (doc: any) => {
  // GROSSWEIGHT
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(20);
  const GW = "     kg";
  doc.text(GW, 78, 47);

  // VOLUMEWEIGHT
  doc.setFont("Montserrat", "semibold");
  doc.setTextColor("#333333");
  doc.setFontSize(9);
  const VW = `       x      x      cm`;
  doc.text(VW, 78, 52);

  // CW
  doc.setFont("Montserrat", "semibold");
  doc.setTextColor("#333333");
  doc.setFontSize(7);
  doc.text("CW: ", 78, 55.5);
  const CwValue = `     kg`;
  doc.text(CwValue, 84, 55.5);

  // LINE HORIZONTAL
  doc.setLineWidth(0.4);
  doc.line(77, 58, 105, 58);
};

const DeliverySection = async (doc: any) => {
  const leftMargin = 5;
  const rightEndX = 77;

  const { phoneRecipientPositionY, senderPositionY } = await DeliveryAddress(
    doc
  );

  // DESTINATION DISTRICT NAME
  const districtPositionY = phoneRecipientPositionY + 13.5;

  doc.setFontSize(8);
  doc.setFont("Montserrat", "bold");

  const splitedSTTDestinationDistrictName = "";
  doc.text(splitedSTTDestinationDistrictName, leftMargin, districtPositionY);

  // Bottom line - static position
  const endContentY = 92;
  doc.setLineDash([], 0);
  doc.setLineWidth(0.4);
  doc.setDrawColor("#000000");
  doc.line(rightEndX, senderPositionY - 5, rightEndX, endContentY);
  doc.line(0, endContentY, 105, endContentY);

  return {
    startX: rightEndX,
    startY: endContentY
  };
};

const KoliSection = (doc: any) => {
  const leftMargin = 78;

  doc.setTextColor("#333333");
  doc.setFontSize(26);

  const koliPositionY = 67.5;
  doc.setFont("Montserrat", "bold");
  doc.text("", leftMargin, koliPositionY, null, null, "left");

  const endContentY = koliPositionY + 2.5;

  doc.setDrawColor("#333333");
  doc.setLineDash([], 0);
  doc.setLineWidth(0.4);
  doc.line(77, endContentY, 105, endContentY);
};

const RecipientRipperFooterSection = async (doc: any) => {
  const leftMargin = 4;

  doc.setTextColor("#333333");
  doc.setFontSize(5);

  const snkPositionY = 104;
  doc.setFont("Montserrat", "semibold");
  doc.text(translate("receipt.terms"), leftMargin, snkPositionY);
  doc.text(translate("receipt.info"), 101, snkPositionY, null, null, "right");

  doc.setLineDash([1]);
  doc.setDrawColor("#A7A8AA");
  doc.setLineWidth(0.5);
  doc.line(0, snkPositionY + 2, 105, snkPositionY + 2);

  const cut = await import("@/app/ui/assets/images/black-cut.png");
  doc.addImage(
    cut.default,
    "PNG",
    leftMargin,
    snkPositionY + 0.5,
    2.5,
    2.5,
    "cut",
    "FAST"
  );
};

const RecipientBreakdownSection = (doc: any) => {
  const leftMargin = 67;
  const roundLeftPosition = 31;
  let snkPositionY = 126;

  // box
  doc.setDrawColor("#000000");
  doc.setLineDash([], 0);
  doc.setLineWidth(0.3);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(roundLeftPosition, snkPositionY, 72, 17, 1, 1, "FD");
  // line
  doc.setDrawColor("#000000");
  doc.setLineDash([], 0);
  doc.line(
    roundLeftPosition + 37,
    snkPositionY,
    roundLeftPosition + 37,
    snkPositionY + 17
  );

  // line small
  doc.setDrawColor("#000000");
  doc.setLineDash([], 0);
  doc.line(
    roundLeftPosition + 64,
    snkPositionY,
    roundLeftPosition + 64,
    snkPositionY + 4
  );

  // horizontal line
  doc.setDrawColor("#000000");
  doc.setLineDash([], 0);
  doc.line(
    roundLeftPosition + 37,
    snkPositionY + 4,
    roundLeftPosition + 72,
    snkPositionY + 4
  );

  const tariffTitle = startCase(translate("receipt.totalCost"));
  doc.setTextColor("#333333");
  doc.setFont("Montserrat", "extrabold");
  let tariffTitlePadding = 0;

  snkPositionY += 2.5;

  doc.setFontSize(5);
  tariffTitlePadding = 10.7;

  doc.text(
    `    kg`,
    roundLeftPosition + 68,
    snkPositionY + 0.2,
    null,
    null,
    "center"
  );
  doc.text(tariffTitle, leftMargin + 2, snkPositionY + 0.2);

  doc.setFont("Montserrat", "semibold");
  doc.text("Rp", leftMargin + 2 + tariffTitlePadding, snkPositionY + 0.2);

  const tariffPositionY = snkPositionY + 4;
  const tariffPositionYL2 = tariffPositionY + 2;

  doc.setFontSize(5);
  doc.setFont("Montserrat", "bold");
  doc.text(
    translate("receipt.shippingCostV2"),
    leftMargin + 2,
    tariffPositionY
  );
  doc.text(translate("receipt.othersV2"), leftMargin + 2, tariffPositionYL2);

  doc.setFont("Montserrat", "semibold");
  doc.text(":", leftMargin + 13, tariffPositionY);
  doc.text(":", leftMargin + 13, tariffPositionYL2);
  doc.text("Rp", leftMargin + 14, tariffPositionY); // B. Kirim
  doc.text("Rp", leftMargin + 14, tariffPositionYL2); // B. Lainnya
};

const RecipientFooterSection = (doc: any) => {
  const leftMargin = 4;

  doc.setTextColor("#333333");
  doc.setFontSize(5);

  const snkPositionY = 146;
  doc.setFont("Montserrat", "semibold");
  doc.text(translate("receipt.terms"), leftMargin, snkPositionY);
  doc.text(translate("receipt.info"), 101, snkPositionY, null, null, "right");

  const endContentY = snkPositionY + 3;

  return {
    startX: 0,
    startY: endContentY
  };
};

const viralSection = async (doc: any, item: any) => {
  const combineQRTextForRegular = `${process.env.VUE_APP_WEBSITE_LION_PARCEL}/track/stt?q=${item.sttNo}`;
  const qrcodeValue = combineQRTextForRegular;

  const qrcode = await QRCode.toDataURL(qrcodeValue || "", {
    errorCorrectionLevel: "Q"
  }).then((url: string) => {
    return url;
  });

  // BOX
  doc.roundedRect(49, 108, 54, 16.5, 1, 1);

  doc.setFont("Montserrat", "semibold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(translate("receipt.infoTracking"), 51, 115);

  doc.roundedRect(74, 110, 14, 13.5, 0.5, 0.5);

  doc.addImage(qrcode, 74.5, 110.5, 12.5, 12.5);

  doc.setFont("Montserrat", "bold");
  doc.roundedRect(87, 110, 15, 13.5, 0.5, 0.5, "FD");
  doc.setTextColor("#ffffff");
  doc.setFontSize(12);
  doc.text("SCAN", 88, 117);
  doc.setFontSize(10);
  doc.text("DI SINI", 88, 120.5);
};

const TarifSection = (doc: any) => {
  const totalBiaya = "Rp";
  const totalBiayaKirim = "Rp";
  const lainLain = "Rp";

  doc.setTextColor("#333333");
  doc.setFont("Montserrat", "bold");
  doc.setFontSize(6);
  doc.text(startCase(translate("receipt.totalCost")), 78, 75);
  doc.setFontSize(11);
  doc.text(String(totalBiaya), 78, 79);

  // tampilkan biaya-biaya dibawah ini ketika client name customer apps atau client name kosong
  // Biaya Kirim
  doc.setFont("Montserrat", "bold");
  doc.setFontSize(5);
  doc.text(translate("receipt.shippingCostV2"), 78, 83);
  doc.text(":", 89, 83);
  doc.setFont("Montserrat", "normal");
  doc.text(String(totalBiayaKirim), 90, 83);

  // Biaya Lainnya
  doc.setFont("Montserrat", "bold");
  doc.text(translate("receipt.othersV2"), 78, 86);
  doc.text(":", 89, 86);
  doc.setFont("Montserrat", "normal");
  doc.text(lainLain, 90, 86);

  // Pajak BM
  return {
    startX: 0,
    startY: 0
  };
};

const QuoteSection = (doc: any, item: any) => {
  doc.setLineWidth(0.3);
  doc.line(0, 102, 105, 102);
  doc.setFont("Montserrat", "semibold");
  doc.setTextColor("#333333");
  doc.setFontSize(8);
  doc.text(
    doc.splitTextToSize(ellipsisString(item.campaignSttQuote, 120), 90),
    51,
    96.5,
    { align: "center" }
  );
  const endContentY = 20;
  return {
    startX: 0,
    startY: endContentY + 5
  };
};

const RecipientHeaderSection = async (
  doc: any,
  item: any,
  logoAspectRatio: number
) => {
  const newLogo = logoBlack;
  doc.addImage(
    newLogo,
    "PNG",
    5,
    108,
    16,
    logoAspectRatio * 16,
    "logo",
    "FAST",
    0
  );

  doc.setFont("Montserrat", "bold");
  doc.setFontSize(11);
  doc.setTextColor("#333333");
  doc.text(item.sttNo, 5.5, 116.5);
  doc.addImage(item.barcode, "PNG", 6, 117, 41, 8, uuid(), "FAST", 0);

  const sttBookedNameLeftPosition = 5.2;
  doc.setFont("Montserrat", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(5);
  const splitedSttBooked = doc.splitTextToSize(
    `${ellipsisString(item.sttBookedName, 30)}`,
    19
  );
  const sttBookedY = splitedSttBooked.length > 1 ? 138 : 139;
  doc.text(splitedSttBooked, sttBookedNameLeftPosition, sttBookedY);
};

const senderRecipientFooter = async (doc: any) => {
  const sttSenderNameData = "asdasdas";
  const sttRecipient = "";

  doc.setFont("Montserrat", "bold");
  doc.setFontSize(5);
  doc.text(sttSenderNameData, 15, 0);

  doc.setFont("Montserrat", "bold");
  doc.setFontSize(5);
  doc.text(sttRecipient, 15, 2.5);
};

const RecipientDestinationSection = async (doc: any) => {
  const leftMargin = 30;

  const sttDestinationDistrictNameString = "";
  let districtString = doc.splitTextToSize(
    sttDestinationDistrictNameString,
    19
  );
  if (districtString.length > 3) {
    districtString = districtString.slice(0, 3);
    districtString[2] += "...";
  }

  const shipperPositionY = districtString.length > 2 ? 128 : 129;
  const breakdownPositionY = shipperPositionY + 2.5;
  const breakdownPositionYL2 = breakdownPositionY + 2.5;
  doc.setFontSize(5);
  doc.setFont("Montserrat", "bold");
  doc.text(
    startCase(translate("receipt.shipper")),
    leftMargin + 2,
    shipperPositionY
  );
  doc.text(
    startCase(translate("receipt.receiver")),
    leftMargin + 2,
    breakdownPositionY
  );
  doc.text(
    startCase(translate("receipt.destination")),
    leftMargin + 2,
    breakdownPositionYL2
  );

  doc.setFont("Montserrat", "semibold");
  doc.text(":", leftMargin + 14, shipperPositionY);
  doc.text(":", leftMargin + 14, breakdownPositionY);
  doc.text(":", leftMargin + 14, breakdownPositionYL2);

  await senderRecipientFooter(doc);

  doc.setFont("Montserrat", "semibold");
  doc.text(districtString, leftMargin + 15, breakdownPositionYL2);
  const districtHeight = doc.getTextDimensions(districtString).h;
  const breakdownPositionYL3 = breakdownPositionYL2 + districtHeight + 0.6;
  const breakdownPositionYL4 = breakdownPositionYL3 + 2.5;

  doc.setFont("Montserrat", "bold");
  doc.text(
    startCase(translate("receipt.createdAtV2")),
    leftMargin + 2,
    breakdownPositionYL3 + 4
  );
  doc.text(
    startCase(translate("receipt.estimation")),
    leftMargin + 2,
    breakdownPositionYL4 + 4
  );
  const sttCreatedAt = "";
  doc.setFont("Montserrat", "semibold");
  doc.text(":", leftMargin + 14, breakdownPositionYL3 + 4);
  doc.text(":", leftMargin + 14, breakdownPositionYL4 + 4);
  doc.text(sttCreatedAt, leftMargin + 15, breakdownPositionYL3 + 4);

  const estimateSla = "";
  doc.text(estimateSla, leftMargin + 15, breakdownPositionYL4 + 4);
};

const generateResiA6 = async (data: PrintDataEntitas[]) => {
  const paper = "a6";
  let position = 0;
  const doc: any = new jsPDF("p", "mm", paper, true);

  const newLogo = logoBlack;
  const logoProperties = doc.getImageProperties(newLogo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  for (const item of data) {
    if (position > 0) {
      position = 0;
      doc.addPage(paper, "p");
    }

    await headerSection(doc, item, logoAspectRatio);
    originDestination(doc, item);
    ursaSla(doc);
    weightsSection(doc);
    await DeliverySection(doc);
    KoliSection(doc);
    await RecipientRipperFooterSection(doc);
    RecipientBreakdownSection(doc);
    RecipientFooterSection(doc);
    viralSection(doc, item);
    TarifSection(doc);
    QuoteSection(doc, item);
    await RecipientHeaderSection(doc, item, logoAspectRatio);
    await RecipientDestinationSection(doc);

    position++;
  }

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default generateResiA6;
