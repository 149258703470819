
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import isUrlUpdateVersion from "@/app/infrastructures/misc/common-library/IsUrlUpdateVersion";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { ManualSTTController } from "@/app/ui/controllers/ManualSTTController";
import { SHIPMENT_STT_MANUAL } from "@/app/infrastructures/misc/RolePermission";
import GenerateSTT from "./component/add.vue";
import { ClientController } from "@/app/ui/controllers/ClientController";
import generatePDF from "@/app/ui/modules/form/document/index";
import generateResiV2 from "@/app/ui/modules/receipt-v2/manual-stt-resi-blank/v2/index";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import JsBarcode from "jsbarcode";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import debounce from "lodash/debounce";
import {
  ClientMappingList,
  RequestApiClientMappingList
} from "@/domain/entities/Client";

@Options({
  components: {
    EmptyState,
    GenerateSTT
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.checkedData.length > 0) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
})
export default class ManualSTT extends Vue {
  mounted() {
    ManualSTTController.initManualSTT();
  }

  onRequest() {
    ManualSTTController.fetchManualSTTList();
  }

  get getCurrentRoute() {
    return this.$route.meta.name;
  }
  get isCreateAble() {
    return checkRolePermission(SHIPMENT_STT_MANUAL.CREATE) && !this.isError;
  }
  get isDownloadAble() {
    return checkRolePermission(SHIPMENT_STT_MANUAL.DOWNLAOD) && !this.isError;
  }

  get paginationStyle() {
    if (
      isUrlUpdateVersion("sttManual", "v2") === "v3" ||
      isUrlUpdateVersion("sttManual", "v2") === "v2"
    ) {
      return "v3";
    }
    return "v1";
  }

  get firstRequest() {
    return ManualSTTController.firstRequest;
  }
  get ManualSTT() {
    return ManualSTTController.ManualSTTData.data;
  }

  // filter Search
  onSearch(value: string) {
    ManualSTTController.searchAction(value);
  }
  get searchValue() {
    return ManualSTTController.search;
  }
  clearSearch() {
    ManualSTTController.clear();
  }

  // renewal
  statusSelectRenewal = false;
  statusNameRenewal = "";
  statusDataRenewal = [
    { name: "Semua Status", value: "" },
    { name: "Used", value: "used" },
    { name: "Unused", value: "unused" }
  ];
  onOpenStatusSelectRenewal() {
    this.statusSelectRenewal = true;
  }
  onCloseStatusSelectRenewal() {
    this.statusSelectRenewal = false;
  }
  onSelectStatusRenewal(name: string, value: string) {
    ManualSTTController.selectStatusAction({
      name: name,
      value: value
    });
    this.statusNameRenewal = name;
    this.onCloseStatusSelectRenewal();
  }

  get isOpenManualSTT() {
    return ManualSTTController.isOpenManualSTT;
  }

  onOpenManualSTT() {
    ManualSTTController.setIsOpenManualSTT(true);
  }

  onCloseManualSTT() {
    ManualSTTController.setIsOpenManualSTT(false);
  }

  // handling
  get isLoading() {
    return ManualSTTController.isLoading;
  }
  get isError() {
    return ManualSTTController.isError;
  }
  get errorCause() {
    return ManualSTTController.isErrorCause;
  }
  get pagination() {
    return ManualSTTController.ManualSTTData.pagination;
  }

  columns = [
    {
      name: "No. STT",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Client/Partner",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      <span class="whitespace-no-wrap">
                        ${item.sttManualName}
                      </span>
                  </div>
                </div>`;
      }
    },
    {
      name: "Kode Client/Partner",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.sttManualCode}</span>`;
      }
    },
    {
      name: "MIXPACK",
      styleHead: "w-1/12 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${
          item.sttManualMixpack === true ? "Yes" : "No"
        }</span>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-3/12 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${formatDate(
          item.sttManualCreatedAt
        )}</span>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-2/12 text-left",
      render: (item: any) => {
        return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center ${
            item.sttManualStatus.toLowerCase() === "used"
              ? "bg-green-lp-300 text-green-lp-200"
              : item.sttManualStatus.toLowerCase() === "unused"
              ? "bg-red-300 text-red-lp-100"
              : "bg-red-300 text-red-lp-200"
          }"
        ><span class="capitalize">${item.sttManualStatus.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  // checkbox table
  checkedData = [];

  filterCheckBox(item: any) {
    return item && item.sttManualStatus.toLowerCase() === "unused";
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get isParentClient() {
    return (
      this.dataProfile.account_type_detail.parentId === 0 &&
      this.roleAccount === "client"
    );
  }

  async downloadCheckbox(data: any, type: string) {
    const roleAccount = this.roleAccount;
    const logo: any = await import(
      `@/app/ui/assets/images/logo-${roleAccount}.png`
    );
    const barcodeList: any[] = [];
    const vendorRefCodeList: any[] = [];
    const listSttPieces: any[] = [];
    data.map((item: any, index: number) => {
      item = new PrintDataEntitas({
        sttNo: item.id,
        sttBookedName: item.sttClientName,
        campaignSttQuote: item.campaignSttQuote
      });
      // barcode
      const barcode = document.createElement("img");
      JsBarcode(barcode, item.sttNo, {
        textAlign: "left",
        displayValue: false,
        fontSize: 16
      });

      // barcode
      const barcodeValue = barcode.src;

      barcodeList.push({
        sttNo: item.sttNo,
        barcode: barcodeValue
      });

      vendorRefCodeList.push({
        sttVendorRefferenceNo: "",
        vendorRefCode: ""
      });

      listSttPieces.push({
        ...item,
        barcode: barcodeList[index].barcode,
        vendorRefCode: vendorRefCodeList[index].vendorRefCode
      });
      barcode.remove();
    });

    // generate
    new Promise(resolve => {
      MainAppController.showLoading();
      setTimeout(resolve.bind(null, null), 1);
    }).then(() => {
      if (type === "form") {
        generatePDF(data, logo.default, barcodeList);
      } else {
        generateResiV2(listSttPieces);
      }
      MainAppController.closeLoading();
    });
  }

  // select-search-client-partner
  get isFilterClient() {
    return (
      this.roleAccount === "internal" ||
      this.isParentClient ||
      AccountController.accountData.isCustomerService
    );
  }
  get placeHolderSearchClientPartner() {
    if (
      this.roleAccount === "internal" ||
      AccountController.accountData.isCustomerService
    ) {
      return "Ketik atau pilih client/partner";
    }
    return "Ketik atau pilih client branch";
  }
  selectSearchClientPartnerValue = "";
  get searchClientPartnerList() {
    if (this.isParentClient) {
      return ClientController.clientMappingData.map(
        (client: ClientMappingList) => ({
          name: client.clientName,
          code: client.clientId
        })
      );
    }
    return ClientController.clientPartnerData.map(e => ({
      name: e.name,
      code: e.id
    }));
  }
  statusSelectSearchClientPartner = false;
  onSelectSearchClientPartner = debounce((value: string) => {
    if (!value)
      ManualSTTController.selectClientPartnerAction({
        name: "",
        value: ""
      });

    this.selectSearchClientPartnerValue = value;
    if (this.isParentClient) {
      ClientController._onGetCLientMappingList(
        new RequestApiClientMappingList({
          page: 1,
          limit: 10,
          search: value,
          isBranch: true
        })
      );
    } else {
      ClientController._onGetListPartner({
        page: 1,
        limit: 10,
        search: value,
        partnerType: "",
        isBranch: true
      });
    }
  }, 500);
  onOpenSelectSearchClientPartner() {
    this.statusSelectSearchClientPartner = true;
    if (this.isParentClient) {
      ClientController._onGetCLientMappingList(
        new RequestApiClientMappingList({
          page: 1,
          limit: 10,
          search: "",
          isBranch: true
        })
      );
    } else {
      ClientController._onGetListPartner({
        page: 1,
        limit: 10,
        search: "",
        partnerType: "",
        isBranch: true
      });
    }
  }
  onCloseSelectSearchClientPartner() {
    this.statusSelectSearchClientPartner = false;
  }
  onChooseSelectSearchClientPartner(name: string, code: string) {
    this.selectSearchClientPartnerValue = name;
    this.statusSelectSearchClientPartner = false;
    ManualSTTController.selectClientPartnerAction({
      name: name,
      value: code
    });
  }

  // confirmation leave
  openConfirmationLeave = false;
  newPath = "";
  answer = false;
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
}
